const ordersData = [{
        status: 'Completed',
        date: '03 Mar, 2020',
        type: 'Buy',
        quantity: '10.32014',
        currency: 'Bitcoin',
        price: '1.00952',
        total: '9067.62'
    },
    {
        status: 'Completed',
        date: '04 Mar, 2020',
        type: 'Buy',
        quantity: '10.32014',
        currency: 'Litecoin',
        price: '0.00214',
        total: '9067.62'
    },
    {
        status: 'Pending',
        date: '05 Mar, 2020',
        type: 'Sell',
        quantity: '10.32014',
        currency: 'Bitcoin',
        price: '1.00952',
        total: '9067.62'
    },
    {
        status: 'Completed',
        date: '03 Mar, 2020',
        type: 'Sell',
        quantity: '10.32014',
        currency: 'Ethereum',
        price: '0.00413',
        total: '2123.01'
    },
    {
        status: 'Completed',
        date: '08 Mar, 2020',
        type: 'Buy',
        quantity: '10.32014',
        currency: 'Litecoin',
        price: '1.00124',
        total: '1802.62'
    },
    {
        status: 'Pending',
        date: '06 Mar, 2020',
        type: 'Sell',
        quantity: '10.32014',
        currency: 'Ethereum',
        price: '0.00413',
        total: '2123.01'
    },
    {
        status: 'Failed',
        date: '05 Mar, 2020',
        type: 'Buy',
        quantity: '10.32014',
        currency: 'Bitcoin',
        price: '1.00952',
        total: '9067.62'
    },
    {
        status: 'Completed',
        date: '03 Mar, 2020',
        type: 'Buy',
        quantity: '10.32014',
        currency: 'Ethereum',
        price: '0.00413',
        total: '2123.01'
    },
    {
        status: 'Pending',
        date: '10 Mar, 2020',
        type: 'Buy',
        quantity: '10.32014',
        currency: 'Bitcoin',
        price: '1.00952',
        total: '9067.62'
    },
    {
        status: 'Pending',
        date: '18 Mar, 2020',
        type: 'Buy',
        quantity: '10.32014',
        currency: 'Litecoin',
        price: '0.00224',
        total: '1773.01'
    },
    {
        status: 'Completed',
        date: '03 Mar, 2020',
        type: 'Buy',
        quantity: '10.32014',
        currency: 'Bitcoin',
        price: '1.00952',
        total: '9423.73'
    },
    {
        status: 'Pending',
        date: '15 Mar, 2020',
        type: 'Sell',
        quantity: '10.32014',
        currency: 'Ethereum',
        price: '0.00413',
        total: '2123.01'
    },
    {
        status: 'Completed',
        date: '03 Mar, 2020',
        type: 'Sell',
        quantity: '10.32014',
        currency: 'Bitcoin',
        price: '1.00952',
        total: '9067.62'
    },
    {
        status: 'Failed',
        date: '01 Mar, 2020',
        type: 'Sell',
        quantity: '10.32014',
        currency: 'Litecoin',
        price: '1.00217',
        total: '9067.62'
    }
]

export { ordersData };