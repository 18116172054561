<script>
import DatePicker from "vue2-datepicker";

import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import { ordersData } from "./data";

import ApiClass from "../../../api/api";
/**
 * Orders component
 */
export default {
  page: {
    title: "Orders",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    DatePicker,
    Layout,
    PageHeader,
  },
  data() {
    return {
      responseData: "",
      ordersData: ordersData,
      title: "Orders",
      items: [
        {
          text: "Dashboards",
          href: "/admin",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "created_at",
          sortable: true,
        },
        {
          key: "order_type",
          sortable: true,
        },
        {
          key: "currency",
          sortable: true,
        },
        {
          key: "quantity",
          sortable: true,
        },
        {
          key: "at_price",
          sortable: true,
        },
        {
          key: "total",
          sortable: true,
        },
        {
          key: "current_status",
          sortable: true,
        },
      ],
      coinvalue: "",
      statusValue: "",
      typeValue: null,
      datepickervalue: "",

      pageid: 1,
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    // this.userOrders();

    // Set the initial number of items
    // this.totalRows = this.items.length;
  },
  methods: {
    myProvider(ctx, callback) {
      ApiClass.getRequest(
        "admin/users/orders/get/" +
          this.id +
          "?per_page=" +
          ctx.perPage +
          "&page=" +
          ctx.currentPage,
        true
      )
        .then((res) => {
          if (res.data.status_code == 1) {
            const items = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.totalRows = res.data.data.total;

            callback(items);
          }
        })
        .bind(this);
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-3">Orders</h4>
                    <div class="row mt-5">
           
                                <div class="col-sm-12 col-md-6">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center fw-normal">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm" class="form-select form-select-sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-6">
                                    <div id="tickets-table_filter" class="dataTables_filter text-end">
                                        <label class="d-inline-flex align-items-center fw-normal">
                                            Search:
                                            <b-form-input v-model="filter" type="search" class="form-control form-control-sm ms-2"></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive mt-3 mb-0">
                                <b-table   :items="myProvider" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">

                                   <template v-slot:cell(created_at)="data">
                                      {{ new Date(data.item.created_at).toLocaleString()}}
                                     </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-end">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                             
                                            <b-pagination     v-model="currentPage" :total-rows="totalRows" :per-page="perPage"  ></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</Layout>
</template>
